import { Box, Card, CardHeader } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAllOrganizationsQuery } from '../graphql/generated'
import { IconOrganization } from '../icons'
import { Wrapper } from '../layout/wrapper'
import { CardErrorContent } from '../shared/card-error-content'
import Protected from '../shared/protected-route'
import { defined, NotOptional } from '../shared/typescript'
import { TableRouteButton } from '../table/button'
import { DataTable } from '../table/table'

export const OrganizationListRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const organizationQuery = useAllOrganizationsQuery()
  const rows = organizationQuery.data?.organizations?.filter(defined).map((x) => ({
    url: `/organization/${x.id}`,
    id: x.id,
    name: x.name,
    roleCount: 0,
    userCount: 0,
    serviceCount: 0,
  }))
  type Row = NotOptional<typeof rows>[0]

  const loading = organizationQuery.loading
  const error = organizationQuery.error
  const refresh = () => organizationQuery.refetch()
  const avatar = DataTable.useAvatarColumn(IconOrganization)
  return (
    <Protected allowedRoles={['IDENTITYSERVER_SUPERUSER', 'IDENTITYSERVER_ORGANIZATION_ADMIN']}>
    <Wrapper title="Organizations">
      <Box margin={2}>
        <Card>
          <CardHeader title="Organizations" />
          <CardErrorContent onRetry={refresh} error={error?.message} />
          <DataTable
            toolbar={<TableRouteButton startIcon={<AddIcon />} to={`/organizations/new`} children="Add New Organization" />}
            columns={[avatar, { field: 'name', headerName: `Name`, flex: 1 }]}
            data={rows ?? []}
            refresh={refresh}
            loading={loading}
            actions={[
              (row: Row) => ({
                iconProps: {},
                icon: `group_work`,
                tooltip: t('OrganizationListRolesTooltip', { count: row.roleCount }),
                onClick: () => navigate(`/organization/${row.id}/tenants`),
              }),
              (row: Row) => ({
                iconProps: {},
                icon: `delete`,
                tooltip: t('OrganizationDeleteTooltip', { count: row.roleCount }),
                onClick: () => navigate(`/organization/${row.id}/delete`),
              }),
            ]}
          />
        </Card>
      </Box>
    </Wrapper>
    </Protected>
  )
}
